import axios from 'axios';

const baseURL = 'https://us-central1-monie-e7a9a.cloudfunctions.net/api/';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Authorization: '',
};

const fetch = axios.create({
  baseURL: baseURL,
});

export const get = (route: any, params: any, requiresAuth = false) => {
  return xhr(route, params, 'GET', requiresAuth);
}

export const post = (route: any, params: any, requiresAuth = false, isForm = false) => {
  return xhr(route, params, 'POST', requiresAuth, isForm);
}

export const deleted = (route: any, params: any, requiresAuth = false) => {
  return xhr(route, params, 'DELETE', requiresAuth)
}

export const patch = (route: any, params: any, requiresAuth = false, isForm = false) => {
  return xhr(route, params, 'PATCH', requiresAuth, isForm)
}


const xhr = async (route: any, params: any, verb: any, requiresAuth: boolean, isForm?: boolean) => {
  const url = route;
  let headers = DEFAULT_HEADERS;
 
  if (requiresAuth) {
    headers = {
      "Content-Type": isForm ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${requiresAuth}`,
      
    }
  }

  // const data = new FormData();
  // if (verb === 'PATCH') {
  //   data.append('name', params.name);
  //   data.append('dob', params.dob);
  //   data.append('email', params.email);
  //   data.append('nationality', params.nationality);
  //   data.append('address', params.address);
  //   data.append('interest', params.interest)
  // }

  
  let resp;
  switch (verb) {
    case 'GET':
      resp = await fetch({
        method: verb,
        url,
        headers,
        params,
      })
      break;
    case 'POST':
      resp = await fetch({
        method: verb,
        url,
        headers,
        data: params,
        // params
      })
      break;
      case 'PATCH':
      resp = await fetch({
        method: verb,
        url,
        headers,
        data: params,
        // params
      });
      break;
    default:
      resp = await fetch({
        method: verb,
        url,
        headers,
        data: params,
        // params
      })
      break;
  }
  const info = resp;

  if (info.status >= 400) {
    throw info;
  }
  return resp;
}