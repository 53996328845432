import React, { ReactElement } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { Location } from 'history';
import AuthPage from './auth';
import QrCodePage from './qrcode';
import InfoPage from './info';
import { ProtectedRoute } from './protectedRooutes';
export const App: React.FC = (): ReactElement => {
  const location: Location = useLocation();
 
  return (
    // <div className="main-bg">
    <CSSTransition key={location.key} classNames="fade" timeout={300}>
       <TransitionGroup className="main-wrapper">
          <Switch location={location}>
            <Router>
              <Route exact path="/" component={AuthPage} />
              <ProtectedRoute path="/store/qrscan" component={QrCodePage} />
              <ProtectedRoute path="/store/info" component={InfoPage} />
            </Router>
          </Switch>
        </TransitionGroup>
      </CSSTransition>
    // </div>
  )
}
