import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Image, Form, Input, notification } from 'antd';
import Monie from '../../assets/images/monie.png';
import { ButtonPrimary } from '../../globalComponents/button';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setError, setLoading } from '../../store/globalSlice/globalSlice';
import { onLogin } from '../../store/auth/authSlice';

interface LoginCred {
  email: string;
  password: string;
}

const loginState: LoginCred = {
  email: '',
  password: '',
}


const AuthPage: React.FC = () => {
  const { idToken } = useAppSelector(state => state.authReducer);
  const { isLoading, hasError, message, errorMessage} = useAppSelector(state => state.globalReducer);
  const [loginCred, setLoginCred] = useState<LoginCred>(loginState);
  const isLoggin_in = useRef(false);
  const dispatch = useAppDispatch();
  const history: History = useHistory();
  const handleEmailChange = (email: FormEvent<HTMLInputElement>) => {
    setLoginCred({
      ...loginCred,
      email: email.currentTarget.value.trim()
    })
  }

  const handlePasswordChange = (password: FormEvent<HTMLInputElement>) => {
    setLoginCred({
      ...loginCred,
      password: password.currentTarget.value
    })
  }


  const onSubmit = () => {
    dispatch(onLogin(loginCred));
    isLoggin_in.current = true;
  }

  const checkAction = () => {
    if (isLoggin_in.current && !isLoading && !hasError) {
      isLoggin_in.current = false;
      notification['success']({ message: message,
      key: 'login'
    });
    dispatch(setLoading({
      isLoading: false,
      message: ''
    }))
      history.push('/store/qrscan');
      return;
    }
    if (isLoggin_in.current && !isLoading && hasError) {
      isLoggin_in.current = false;
      notification['error']({ message: errorMessage,
        key: 'login'
      });
      dispatch(setError({
        hasError: false,
        errorMessage: ''
      }))
      return;
    }
  }

  const checkAuth = () => {
    if (idToken !== null) {
      history.push('/store/qrscan');
    }
  }

  useEffect(checkAction, [dispatch, errorMessage, hasError, history, isLoading, message])
  useEffect(checkAuth, [history, idToken]);
  return(
    <div className='body'>
      <div className='login-container'>
        <div className='logo-container'>
         <Image src={Monie} preview={false} />
        </div>
      <Form
        className='login-form'
        onFinish={onSubmit}
      >
      <Form.Item
        className='form-input card'
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email',
            type: 'email'
          },
        ]}
      >
          <Input placeholder="Email" className='login-input' onChange={handleEmailChange}/>
        </Form.Item>
        <Form.Item
          className='form-input card'
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password',
            },
          ]}
        >
          <Input.Password placeholder="Password" className='login-input' onChange={handlePasswordChange}/>
        </Form.Item>
        <ButtonPrimary
          label='Login'
          onClick={() => {}}
          width={250}
          height={40}
          loading={isLoading}
        />
      </Form>
      </div>
    </div>
  )
}

export default AuthPage;