import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';

export const ProtectedRoute = ({
  component: Component,
  ...routeProps
}: any) => {
  const { idToken } = useAppSelector(state => state.authReducer);
  return (
    <Route
      {...routeProps}
      render={
        idToken !== null ?
        props => {
        return (
          <Component {...props} />
        )
      }
    : () => {
        return (
          <Redirect
          to={{ pathname: '/' }}
        />
        )
      }
    }
    />
  )
};
