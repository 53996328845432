import { SagaIterator } from 'redux-saga';
import { PayloadAction} from '@reduxjs/toolkit';
import { LoginProps } from './types';
import { notification } from 'antd';
import { all, takeLatest, call, put, take, fork, } from 'redux-saga/effects';
import { setError, setLoading } from '../globalSlice/globalSlice';
import { MonieFirebase } from '../../constants/firebase';
import { MONIEFirebaseSaga } from '../root-saga';
import { onLogin, onLogout, setAuth } from './authSlice';

export function* getToken() {
  const token: string | undefined = yield MonieFirebase
    .auth().currentUser?.getIdToken().then(token => token);
    return token;
}

function* login({payload}: PayloadAction<LoginProps>): SagaIterator<void> {
  try {
    yield put(setLoading({
      isLoading: true,
      message: '',
    }));
    const login = yield call(MONIEFirebaseSaga.auth.signInWithEmailAndPassword, payload.email, payload.password);
    if (login) {
      const token = yield call(getToken);
      const authorization = {
        idToken: token
      }
      yield put(setLoading({
        isLoading: false,
        message: 'Login Successful',
      }));
      yield put(setAuth(authorization))
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(setError({
        hasError: true,
        errorMessage: error.message
      }));
      yield put(setLoading({
        isLoading: false,
        message: '',
      }));
    }
  }
}

function* authLogout({ history }: any): SagaIterator<void> {
  try {
    const payload = { idToken: null };
    yield call(MONIEFirebaseSaga.auth.signOut);
    yield put(setAuth(payload));

    history.push('/')

    notification['info']({
      message: 'You have been logged out',
      description: '',
      key: 'logout'
    });
  } catch (error) {}
}

function* onCheckAuthenticatedUser(): SagaIterator<void> {

  const channel = yield call(MONIEFirebaseSaga.auth.channel);
  while(true) {
    const { user } = yield take(channel);
    if (user) {
      const refreshToken = yield user.getIdToken().then(async (token: any) => await token);
      yield put(setAuth({
        idToken: refreshToken
      }));
    } else {
      yield put(setAuth({
        idToken: null
      }));
    }
  }
}


export function* authSaga(): SagaIterator<void> {
    yield all([
      yield fork(onCheckAuthenticatedUser),
      takeLatest(onLogin, login),
      takeLatest(onLogout, authLogout)
    ])
  } 