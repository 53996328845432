import Firebase from 'firebase/app'
import '@firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

export interface FirebaseConfig {
  apiKey: string;
  authDomain?: string;
  // databaseUrl?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

const monieFirebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyCtSitSE9jIrUeJW_HKj5MCvrjfDGr9PP0",
  authDomain: "monie-e7a9a.firebaseapp.com",
  projectId: "monie-e7a9a",
  storageBucket: "monie-e7a9a.appspot.com",
  messagingSenderId: "290811953554",
  appId: "1:290811953554:web:8b1521753cf0bf6272814b",
  measurementId: "G-XT04F42375"
};


export const MonieFirebase: Firebase.app.App = Firebase.initializeApp(
  monieFirebaseConfig
);

export const firestore = MonieFirebase.firestore();
