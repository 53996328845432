import React from 'react';
import { Modal } from 'antd';
import { ButtonPrimary } from '../../../globalComponents/button';
import moment from 'moment';

export const SuccessPage = ({
  visible,
  cashback,
  usersInputted,
  dateTime,
  onCancel,
  onConfirm,
  isLoading
}: any) => {
  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
    >
      <div className='modal-container'>
        <div className='sucess-div'>
          <div className='title-text'>€ Monie Cashback!</div>
          <div style={{ display: 'flex'}}>
            <b style={{ marginRight: '10px'}}>Amount inputed:</b>
            <p>€{usersInputted.amount}</p>
          </div>
          <div style={{ display: 'flex'}}>
            <b style={{ marginRight: '10px'}}>Date and Time:</b>
            <p>{moment(dateTime, 'DD/MM/YYYY HH:mm A').format('DD/MM/YYYY hh:mm A')
            }</p>
          </div>
          <div style={{ display: 'flex'}}>
            <b style={{ marginRight: '10px'}}>Receipt Number:</b>
            <p>{usersInputted.receipt}</p>
          </div>
          {/* <div style={{ display: 'flex'}}>
            <b style={{ marginRight: '10px'}}>Money returned:</b>
            <p>€{cashback.amount}</p>
            
          </div> */}
        </div>
        <ButtonPrimary
          label='Confirm'
          onClick={onConfirm}
          loading={isLoading}
        />
      </div>
    </Modal>
  )
}