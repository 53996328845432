import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthState, LoginProps } from './types';


const initialState = {
  idToken: null,
  } as AuthState;



export const authlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.idToken = action.payload.idToken
    },
    onLogin: (state, action: PayloadAction<LoginProps>) => {
    },
    onLogout: (state, action: PayloadAction<any>) => { },
  }
});

export const {
  setAuth,
  onLogin,
  onLogout
} = authlice.actions;
export default authlice.reducer;