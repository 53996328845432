/* eslint-disable no-restricted-globals */
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Upload, notification, DatePicker } from 'antd';
import { ButtonPrimary, LogoutButton } from '../../globalComponents/button';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetCashBack, onSubmitTransaction } from '../../store/transaction/transactionSlice';
import { transactionProp } from '../../store/transaction/types';
import { setError, setLoading } from '../../store/globalSlice/globalSlice';
import { SuccessPage } from './components/SuccessScreen';
import { BiLogOutCircle } from 'react-icons/bi';
import { onLogout } from '../../store/auth/authSlice';


interface FieldProps {
  amount: any;
  eligible: boolean;
  receipt: string;
}

const fieldState: FieldProps = {
  amount: '1',
  eligible: true,
  receipt: ''
}

const InfoPage: React.FC = () => {
  const { isLoading, hasError, message, errorMessage} = useAppSelector(state => state.globalReducer);
  const { cashback } = useAppSelector(state => state.transactionReducer);
  const [userInput, setUserInput] = useState<FieldProps>(fieldState);
  const [dateSelected, setSelectedDate] = useState<any>(moment().format('DD-MM-YYYY HH:mm'));
  const [visible, setVisible] = useState(false);
  const [photo, setPhoto] = useState<any>();
  const [form] = Form.useForm();
  const isSubmitRef = useRef(false);
  const isSubmitCashbackRef = useRef(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history: History = useHistory();
  // const onChecked = () => {
  //   setUserInput({
  //     ...userInput,
  //     eligible: !userInput.eligible
  //   })
  // }

  const onCancelModal = () => {
    setVisible(false);
  }
  const onChangeDate = (value: any) => {
    const date = moment(value).format('DD-MM-YYYY')
    setSelectedDate(date);
  }
  const onChangeTime = (value: any) => {
    const time = moment(value).format('HH:mm');
    const date = `${dateSelected} ${time}`;
    setSelectedDate(date);
  }

  const onHandleFieldAmountChange = (val: number) => {
    setUserInput({
      ...userInput,
      amount: Number(val)
    })
  }

  const onHandleReceiptFieldChange = (e: FormEvent<HTMLInputElement>) => {
    setUserInput({
      ...userInput,
      receipt: e.currentTarget.value
    })
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    setPhoto(e.fileList[0].originFileObj);
    return e && e.fileList;
  };

  const onSubmit = () => {
    const cashBackBody = {
      amount: userInput.amount,
      date: dateSelected
    }
    form.resetFields();
    dispatch(onGetCashBack(cashBackBody));
    isSubmitCashbackRef.current = true;
  }

  const onConfirm = () => {
    const date = moment(dateSelected, 'DD-MM-YYYY HH:mm').toDate();
    const type = photo.type.split('image/')[1];
    const file = new File([photo], `${photo.name.split(`.${type}`)[0]}_${moment().unix()}.${type}`, {type: photo.type })
    const body: transactionProp = {
      qr: location.state.data,
      datepurchased: new Date(date),
      cashback: cashback,
      file: file,
      ...userInput
    }
  
    dispatch(onSubmitTransaction(body));
    isSubmitRef.current = true;
  }

  const onClickLogout = () => {
    const isLogout = confirm('Are you sure you want to logout?');

    if (isLogout) {
      dispatch(onLogout(history));
    }
  }

  const disabledSubmissionDate = (submissionValue: any) => {
    if (!submissionValue) {
        return false;
    }
    const futureMonth = moment().endOf('month')
    return (submissionValue.valueOf() < moment().startOf('month') || submissionValue.valueOf() > futureMonth);
  }

  const checkAction = () => {
      if (isSubmitRef.current && !isLoading && !hasError) {
        isSubmitRef.current = false;
        visible && notification['success']({ message: message,
        key: 'info'
      });
      setVisible(false);
      dispatch(setLoading({
        isLoading: false,
        message: ''
      }));
      setUserInput(fieldState);
      setTimeout(() => {
        history.push('/store/qrscan');
        window.location.reload();
      }, 1000)
      return;
      }
      if (isSubmitCashbackRef.current && !isLoading && !hasError) {
        isSubmitCashbackRef.current = false;
          visible && notification['success']({ message: message,
          key: 'cashback'
        });
        dispatch(setLoading({
          isLoading: false,
          message: ''
        }))
        setVisible(!visible);
        return;
      }
      if (isSubmitRef.current && !isLoading && hasError) {
        isSubmitRef.current = false;
        notification['error']({ message: errorMessage,
          key: 'infoError'
        });
        dispatch(setError({
          hasError: false,
          errorMessage: ''
        }))
        return;
      }
      if (isSubmitCashbackRef.current && !isLoading && hasError) {
        isSubmitCashbackRef.current = false;
        notification['error']({ message: errorMessage,
          key: 'cashback'
        });
        dispatch(setError({
          hasError: false,
          errorMessage: ''
        }))
        return;
      }
    }

  useEffect(checkAction, [dispatch, errorMessage, hasError, history, isLoading, message, visible]);
  return(
  <div className='body'>
    <div className='logout-style'>
      <LogoutButton
          label='Logout'
          onClick={onClickLogout}
        />
      </div>
    <div className='info-container'>
      <div className='header-text'>
        FILL-IN INFORMATION
      </div>
      <Form
        className='info-form'
        onFinish={onSubmit}
        // initialValues={userInput}
        form={form}
      >
        <Form.Item
          className='info-inputt'
          name="amount"
          rules={[
            {
              required: true,
              message: 'Please input your price',
            },
          ]}
        >
          <InputNumber placeholder="Enter the Price" min={1} className='login-input' onChange={onHandleFieldAmountChange}/>
        </Form.Item>
        <Form.Item
          className='info-inputt'
          name="receipt"
          rules={[
            {
              required: true,
              message: 'Please input your receipt number',
            },
          ]}
        >
          <Input placeholder="Enter the Receipt Number" className='login-input' onChange={onHandleReceiptFieldChange}/>
        </Form.Item>
        {/* <Form.Item
          className='check-input'
          name="eligible"
        >
          <Checkbox onChange={onChecked} className="login-input" checked={userInput.eligible}><div className='header-text'>Eligible?</div></Checkbox>
        </Form.Item> */}
        {/* <div className='date-optional'>Optional</div> */}
        <Form.Item
         className='info-inputt'
         name='date'
         rules={[{ required: true, message: 'Select Date purchase!' }]}
        >
          <DatePicker disabledDate={disabledSubmissionDate} onChange={onChangeDate} placeholder="Date purchased" className="login-input" />
        </Form.Item>
        <Form.Item
         className='info-inputt'
         name='time'
         rules={[{ required: true, message: 'Select time purchase!' }]}
        >
          <DatePicker onChange={onChangeTime} picker="time" placeholder="Time purchased" className="login-input" />
        </Form.Item>
        <Form.Item
          className='info-inputt'
          name="file"
          valuePropName="fileList"
          rules={[{ required: true, message: 'Please upload an image' }]}
          getValueFromEvent={normFile}
        >
          <Upload name="logo" listType="picture" className='upload-profile' maxCount={1}>
            {/* <Button className='upload-input'>Upload Profil Picture</Button> */}
            <Input
              placeholder="Upload Photo"
              className='login-input'
              prefix={<></>}
              readOnly
            />
          </Upload>
        </Form.Item>
        <ButtonPrimary
          label='Submit'
          onClick={()=> {}}
          width={250}
          height={40}
          loading={!visible && isLoading}
        />
      </Form>
      <SuccessPage
        visible={visible}
        cashback={cashback}
        usersInputted={userInput}
        dateTime={dateSelected}
        onCancel={onCancelModal}
        onConfirm={onConfirm}
        isLoading={isLoading}
      />
    </div>
  </div>
  )
}

export default InfoPage;