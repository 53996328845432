import { useState, useEffect } from 'react';

export const CameraPermissionErrors = {
  ABORT_ERROR: 'AbortError',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_FOUND_ERROR: 'NotFoundError',
  NOT_READABLE_ERROR: 'NotReadableError',
  OVERCONSTRAINED_ERROR: 'OverconstrainedError',
  SECURITY_ERROR: 'SecurityError',
  TYPE_ERROR: 'TypeError',
  UNKNOWN: 'Unknown'
};

const PermissionErrorMessages = {
  ABORT_ERROR: 'Unable to initialize camera, Please try again.',
  NOT_ALLOWED_ERROR:
    'Camera access is not allowed. Check browser settings and allow camera access.',
  NOT_FOUND_ERROR: "Make sure you're using  a device with a camera.",
  NOT_READABLE_ERROR: 'Unable to initialize camera, Please try again.',
  OVERCONSTRAINED_ERROR:
    "Your device's camera is currently not supported. Please try another device.",
  SECURITY_ERROR: 'Camera access is not allowed. Check browser settings and allow camera access.',
  TYPE_ERROR: 'Camera access is not allowed. Check browser settings and allow camera access.',
  UNKNOWN: 'An error occured while trying to access camera. Please contact your administrator.'
};

export const useUserMedia = requestedMedia => {
  const [mediaStream, setMediaStream] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia);
        setMediaStream(stream);
      } catch (e) {
        console.log(`Error Accessing Camera: ${e.name}`);
        switch (e?.name) {
          case CameraPermissionErrors.ABORT_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Error',
              message: PermissionErrorMessages.ABORT_ERROR
            });
            break;
          case CameraPermissionErrors.NOT_ALLOWED_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Access Denied',
              message: PermissionErrorMessages.NOT_ALLOWED_ERROR
            });
            break;
          case CameraPermissionErrors.NOT_FOUND_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Not Found',
              message: PermissionErrorMessages.NOT_FOUND_ERROR
            });
            break;
          case CameraPermissionErrors.NOT_READABLE_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Error',
              message: PermissionErrorMessages.NOT_READABLE_ERROR
            });
            break;
          case CameraPermissionErrors.OVERCONSTRAINED_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Error',
              message: PermissionErrorMessages.OVERCONSTRAINED_ERROR
            });
            break;
          case CameraPermissionErrors.SECURITY_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Access Denied',
              message: PermissionErrorMessages.SECURITY_ERROR
            });
            break;
          case CameraPermissionErrors.TYPE_ERROR:
            setError({
              code: e?.name,
              title: 'Camera Error',
              message: PermissionErrorMessages.TYPE_ERROR
            });
            break;
          default:
            setError({
              code: CameraPermissionErrors.UNKNOWN,
              title: 'Unknown Error Occured',
              message: PermissionErrorMessages.UNKNOWN
            });
        }
        setMediaStream(false);
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      };
    }
  }, [mediaStream, requestedMedia]);

  return { mediaStream, error };
};

export const formatOriginalNumber = val => {
  const validNumnber = val.replace(/[^0-9|-]/g, '').replace(/\./g, '');
  if (Number(validNumnber) === 0 || isNaN(validNumnber)) {
    return null;
  }
  return parseFloat(Number(validNumnber) * 0.01).toFixed(2);
};

export const formatGermanCurrency = value => {
  return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(value);
};
