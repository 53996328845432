import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import globalReducer from './globalSlice/globalSlice';
import authReducer from './auth/authSlice';
import transactionReducer from './transaction/transactionSlice';
// * import reducers here

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    globalReducer,
    authReducer,
    transactionReducer
  });

export type RootReducer = ReturnType<typeof createRootReducer>;
