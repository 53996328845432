import ReduxSagaFirebase from 'redux-saga-firebase';
import { MonieFirebase } from '../constants/firebase';
import { transactionSaga } from './transaction/saga';
import { authSaga } from './auth/saga';
// * import sagas here


export const MONIEFirebaseSaga = new ReduxSagaFirebase(MonieFirebase);

// * include imported sagas here
export const sagas = [
    transactionSaga,
    authSaga
];
