import React from 'react';
import { Button, Row } from 'antd';
import { BiLogOutCircle } from 'react-icons/bi';

interface ButtonProps {
  label: string;
  onClick: () => void;
  loading?: boolean;
  width?: any;
  height?: any;
  size?: any
}

export const ButtonPrimary: React.FC<ButtonProps> = ({
  label,
  loading,
  onClick,
  width, height,
  size
}) => {

  return(
    <div className="button-primary-container">
      <Button
        onClick={onClick}
        loading={loading}
        type="default"
        className='button-primary'
        style={{ width: width, height: height }}
        htmlType="submit"
      >
        <div className='button-text' style={{fontSize: size}}>{label}</div>
      </Button>
    </div>
  )
}


export const LogoutButton: React.FC<ButtonProps> = ({
  label,
  loading,
  onClick,
  width, height,
  size
}) => {

  return(
    <div className="button-logout-container">
      <Button
        onClick={onClick}
        loading={loading}
        type="default"
        className='button-primary'
        style={{ width: width, height: height }}
        htmlType="submit"
      >
        <Row style={{ alignItems: 'center' }}>
          <div style={{ marginTop: '3px', marginRight: '10px' }}>
            <BiLogOutCircle size={15} color="#243592"/>
          </div>
        <div className='button-text' style={{fontSize: size}}>
          {label}
        </div>
        </Row>
      </Button>
    </div>
  )
}