/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import Measure from 'react-measure';
import jsQR from 'jsqr';
import { useHistory } from 'react-router-dom';
import { Spin, notification, Popconfirm } from 'antd';
import { useCardRatio } from '../../constants/use-card-ratio';
import { useUserMedia } from '../../hooks/Utilities';
import { capture_options } from '../../constants/camera-option';
import { useOffsets } from '../../constants/use-offsets';
import { Video, Container, Wrapper } from '../../constants/camera-styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onLogout } from '../../store/auth/authSlice';
import { LogoutButton } from '../../globalComponents/button';
const QrFrameImg = require('../../assets/images/qr-frame.png');

const QrCodePage = () => {
  const videoRef = useRef();
  const timeout = useRef(null);
  const { isLoading } = useAppSelector(state => state.globalReducer);
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const { mediaStream, error: cameraError } = useUserMedia(capture_options);
  const [isQrReaderHasError, setIsQrReaderHasError] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    // const track = mediaStream.getVideoTracks()[0];
    window.stream = mediaStream;
    videoRef.current.srcObject = mediaStream;
    // setTimeout(function () {
    //   var capabilities = track.getCapabilities();
    //   if ('zoom' in capabilities) {
    //     track.applyConstraints({ advanced: [{ zoom: capabilities.zoom.max / 2 }] });
    //   }
    // }, 1000);
  }
  const handleScan = data => {
    console.log(data);
    if (data) {
      history.push('/store/info', { data });
    }
  };

  const handleResize = contentRect => {
    setContainer({
      width: Math.floor(contentRect.bounds.width),
      height: Math.floor(Math.round(contentRect.bounds.width / aspectRatio))
    });
  };

  const handleCanPlay = () => {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    timeout.current = setInterval(() => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = container.width;
        canvas.height = container.height;

        const context = canvas.getContext('2d');
        context.drawImage(
          videoRef.current,
          offsets.x,
          offsets.y,
          container.width,
          container.height
        );
        const imageData = context.getImageData(
          offsets.x,
          offsets.y,
          container.width,
          container.height
        ).data;
        const code = jsQR(imageData, container.width, container.height);
        
        if (code) {
          handleScan(code.data);
        }
      } catch (error) {
        console.log(error);
        clearInterval(timeout.current);
        setIsQrReaderHasError(true);
      }
    })
  }

  const onClickLogout = () => {
    const isLogout = confirm('Are you sure you want to logout?');

    if (isLogout) {
      dispatch(onLogout(history));
    }
  }

  useEffect(() => {
    return () => clearInterval(timeout.current);
  }, []);

  if (mediaStream === false ) {
    notification['error']({ message: cameraError.title,
      key: 'qr'
    });
  }
  if (isQrReaderHasError) {
    notification['error']({ message: cameraError.title,
      key: 'qrCode'
    });
  }
  return(
    <div className='body'>
      <div className='logout-style'>
        <LogoutButton
          label='Logout'
          onClick={onClickLogout}
        />
      </div>
      <div className='qrcode-container'>
      <Spin delay={1000} spinning={isLoading || mediaStream === null}>
      {mediaStream && (
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => (
          <Wrapper>
            <Container
              ref={measureRef}
              maxHeight={videoRef.current && videoRef.current.videoHeight}
              maxWidth={videoRef.current && videoRef.current.videoWidth}
            >
            <Video
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`
              }}
            />
              <img src={QrFrameImg} className="qr-frame" alt="qr-frame" />
            </Container>
          </Wrapper>
        )}
        </Measure>
      )}
      </Spin>
      <div className='text'>Scan User Qr Code</div>
      </div>
    </div>
  )
}

export default QrCodePage;