import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppGlobalsState, LoadingState, ErrorState} from './types';

const initialState = {
  isLoading: false,
  message: '',
  hasError: false,
  errorMessage: '',
} as AppGlobalsState;

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<LoadingState>) => {
      state.isLoading = action.payload.isLoading
      state.message = action.payload.message
    },
    setError: (state, action: PayloadAction<ErrorState>) => {
      state.hasError = action.payload.hasError
      state.errorMessage = action.payload.errorMessage
    }
  }
});

export const {setLoading, setError} = globalSlice.actions;
export default globalSlice.reducer;
