import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { transactionProp, cashbackProps, transactionState } from './types';


const initialState = {
  cashback: {}
  } as transactionState;



export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    onSubmitTransaction: (state, action: PayloadAction<transactionProp>) => {
    },
    onGetCashBack: (state, action: PayloadAction<cashbackProps>) => {

    },
    setCashBack: (state, action: PayloadAction<transactionState>) => {
      state.cashback = action.payload
    }
  }
});

export const {
  onSubmitTransaction,
  onGetCashBack,
  setCashBack,
} = transactionSlice.actions;
export default transactionSlice.reducer;