import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { get, post } from '../../constants/api';
import { MonieFirebase } from '../../constants/firebase';
import { setError, setLoading } from '../globalSlice/globalSlice';
import { onGetCashBack, onSubmitTransaction, setCashBack  } from './transactionSlice';
import { cashbackProps, transactionProp } from './types';


export function* getToken() {
  const token: string | undefined = yield MonieFirebase
    .auth().currentUser?.getIdToken().then((token) => token);
    return token;
}

function* getCashBack({payload}: PayloadAction<cashbackProps>): SagaIterator<void> {
  try {
    yield put(setLoading({
      isLoading: true,
      message: '',
    }));
    const token = yield call(getToken);
    const getCashBack = yield call(get, 'cashback', payload, token);
    yield put(setCashBack(getCashBack.data.data))
    yield put(setLoading({
      isLoading: false,
      message: '',
    }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(setError({
        hasError: true,
        errorMessage: error.message
      }));
      yield put(setLoading({
        isLoading: false,
        message: '',
      }));
    } 
  }
}

function* submitTransaction({ payload }: PayloadAction<transactionProp> ): SagaIterator<void> {
  try {
    yield put(setLoading({
      isLoading: true,
      message: '',
    }));
    const token = yield call(getToken);
    const form = new FormData();
    form.append('amount', payload.amount);
    form.append('cashback', JSON.stringify(payload.cashback));
    form.append('eligible', `${payload.eligible}`);
    form.append('qr', payload.qr);
    form.append('receipt', payload.receipt);
    form.append('datepurchased', payload.datepurchased);
    form.append('file', payload.file);
    const result = yield call(post, 'transactions', form, token, true);
    if (result) {
      yield put(setLoading({
        isLoading: false,
        message: 'Transactions Complete!',
      }));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(setError({
        hasError: true,
        errorMessage: error.message
      }));
      yield put(setLoading({
        isLoading: false,
        message: '',
      }));
    } 
  }
}


export function* transactionSaga(): SagaIterator<void> {
  yield all([
    takeLatest(onSubmitTransaction, submitTransaction),
    takeLatest(onGetCashBack, getCashBack)
  ])
  } 