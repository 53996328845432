import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import {createLogger} from 'redux-logger';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, createStore, Middleware } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { parse, stringify } from 'flatted';
import { createRootReducer, RootReducer } from './root-reducer';

export const history: History = createBrowserHistory();
export const saga: SagaMiddleware = createSagaMiddleware();
const router: Middleware = routerMiddleware(history);

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState),
);

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  transforms: [transformCircular],
  whitelist: [
    'globalReducer',
  ]
};

// const loggerMiddleware = createLogger({
//   predicate: (_getState, _action) => false,
// });



const middlewares = [router, saga];
const middlewareEnhancers = process.env.NODE_ENV === 'production'
  ? applyMiddleware(...middlewares)
  : composeWithDevTools(applyMiddleware(...middlewares));



export const rootReducer: RootReducer = createRootReducer(history);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export const configureStore = (preloadedState?: object | undefined) =>
  createStore(persistedReducer, preloadedState, middlewareEnhancers);

export const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
// persistor.purge();
